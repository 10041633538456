import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
  Switch,
  Table,
  Tooltip,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { ContentHeader, RemoveTokensModal } from "../../components";
import { API_URL } from "../../helpers";
import { END_POINTS } from "../../helpers";

import {
  EyeOutlined,
  DownloadOutlined,
  DeleteOutlined,
  SearchOutlined,
  EditFilled,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  useDeleteUserMutation,
  useLazyGetUsersQuery,
  usePutBlockUserMutation,
} from "../../services/users";
import { errorToast, successToast } from "../../helpers";
import useAuth from "../../hooks/useAuth";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const { confirm } = Modal;

interface DataType {
  _id: any;
  key: string;
  name: string;
  phone: string;
  userName: string;
  email: string;
  status: boolean;
}

type PropTypes = {
  scrollPosition: ScrollPosition;
};

const ManageUsers = ({ scrollPosition }: PropTypes) => {
  const navigate = useNavigate();
  const [userList, userListData] = useLazyGetUsersQuery();

  const [userListState, setUserListState] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number | undefined>();
  const [deleteUser, deleteUserData] = useDeleteUserMutation();
  const [blockUser, blockUserData] = usePutBlockUserMutation();
  const [blockMessage, blockMessageData] = usePutBlockUserMutation();
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });

  const userData = useAuth();

  const [query, setQuery] = useState("");

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getUserDetails(page);
  };

  const handleDelete = async (id: string) => {
    try {
      const res = await deleteUser({ userId: id }).unwrap();

      if (res?.statusCode === 200) {
        successToast("User Deleted Successfully");
        getUserDetails(1);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this user?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record?._id);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const getUserDetails = async (page: number) => {
    try {
      const res = await userList({
        page: page,
        size: 10,
        query: query || "",
      }).unwrap();

      if (res?.statusCode === 200) {
        setUserListState(res?.data?.data);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleBlock = async (isBlocked: boolean, _id: string) => {
    let body = {
      isBlocked: isBlocked,
    };
    try {
      const res = await blockUser({ body, userId: _id }).unwrap();
      if (res?.statusCode === 200) {
        getUserDetails(1);
        successToast(
          isBlocked
            ? "User Deactivated Successfully!"
            : "User Activated Successfully!"
        );
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const handleMessageBlock = async (
    isMessagingBlocked: boolean,
    _id: string
  ) => {
    let body = {
      isMessagingBlocked: isMessagingBlocked,
    };
    try {
      const res = await blockMessage({ body, userId: _id }).unwrap();
      if (res?.statusCode === 200) {
        getUserDetails(1);
        successToast(
          isMessagingBlocked
            ? "Messaging Blocked Successfully!"
            : "Messaging Unblocked Successfully!"
        );
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };
  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex((ele: any) => ele?.label === "Manage Users");
      if (idx != -1) {
        if (!permissions[idx]?.isAdd) {
          let data = {
            isAdd: false,
            isDelete: hidePermission?.isDelete,
          };
          setHidePermission(data);
        }
        if (!permissions[idx]?.isDelete) {
          let data = {
            isAdd: hidePermission?.isAdd,
            isDelete: false,
          };
          setHidePermission(data);
        }
      }
    }
  };

  useEffect(() => {
    if (userData && userData?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);

  const getUserCsv = () => {
    window.open(` ${API_URL + END_POINTS.getUserCsv}`);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      width: "10%",
      align: "center",
      render: (_, { image }: any) => (
        <div>
          <LazyLoadImage
            alt="profile"
            style={{ height: 70, width: 70, borderRadius: 100 }}
            src={image || "/static/images/dummy.png"}
            effect="blur"
            scrollPosition={scrollPosition}
          />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      align: "center",
      render: (_, { firstName }: any) => (
        <div>
          <span>{`${firstName || "N/A"}`}</span>
        </div>
      ),
    },
    {
      title: "Username",
      dataIndex: "userName",
      key: "userName",
      align: "center",
      render: (_, { userName }: any) => (
        <div>
          <span>{`${userName || "N/A"}`}</span>
        </div>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      render: (_, { phone, countryCode }: any) => (
        <div>
          <span>{`${phone ? countryCode + " " + phone : "N/A"}`}</span>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      render: (_, { email }: any) => (
        <div>
          <span>{`${email || "N/A"}`}</span>
        </div>
      ),
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleViewClick(record);
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>

          {hidePermission?.isAdd ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                navigate(`/manageusers/edit/${record?._id}`);
              }}
            >
              <EditFilled style={{ color: "#C69320" }} />
            </Button>
          ) : null}
          {hidePermission?.isAdd ? (
            <RemoveTokensModal id={record?._id} />
          ) : null}
          {hidePermission?.isDelete ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                showDeleteConfirm(record);
              }}
            >
              <DeleteOutlined style={{ color: "#d4380d" }} />
            </Button>
          ) : null}
        </Space>
      ),
    },
    {
      title: "Account Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (_, { isBlocked, _id }: any) => (
        <Switch
          size="small"
          defaultChecked={!isBlocked}
          onChange={() => handleBlock(!isBlocked, _id)}
        />
      ),
    },
    {
      title: "Block Messaging",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (_, { isMessagingBlocked, _id }: any) => (
        <Switch
          size="small"
          defaultChecked={isMessagingBlocked}
          onChange={() => handleMessageBlock(!isMessagingBlocked, _id)}
        />
      ),
    },
  ];

  const handleViewClick = async (record: any) => {
    navigate(`/manageusers/userdetail/${record?._id}/user`);
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    if (query === "") {
      getUserDetails(1);
    }
  }, [query]);

  return (
    <div style={{ padding: 40 }}>
      <ContentHeader heading="Manage Users" />
      <div className="srch">
        <Card>
          <Row
            style={{ justifyContent: "space-between", padding: "10px 0px" }}
            gutter={16}
          >
            <Col xs={24} md={7}>
              <Form
                style={{ display: "flex", gap: 20 }}
                onFinish={() => getUserDetails(1)}
              >
                <Input
                  placeholder="Search Users"
                  prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                  style={{ height: 50, borderRadius: 10 }}
                  onChange={(e) => setQuery(e.target.value)}
                />
                <Button
                  style={{
                    background: "#ECAC35",
                    width: 90,
                    height: 43,
                    margin: "auto",
                    color: "black",
                  }}
                  type="default"
                  htmlType="submit"
                >
                  Search
                </Button>
              </Form>
            </Col>
            <Col
              style={{
                margin: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                icon={<DownloadOutlined />}
                style={{
                  background: "#ECAC35",
                  height: 35,
                  color: "black",
                }}
                type="primary"
                onClick={getUserCsv}
              >
                Export CSV
              </Button>
            </Col>
          </Row>
          <div className="srch" style={{ marginTop: 25 }}>
            <Table
              className="tableSelector"
              columns={columns}
              dataSource={userListState}
              rowKey="_id"
              loading={userListData?.isLoading || blockUserData.isLoading}
              pagination={pagination}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

// export default ManageUsers;
export default trackWindowScroll(ManageUsers);
