import { Button, Card, Col, Form, Input, Upload } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { useEffect, useState } from "react";
import { errorToast, successToast } from "../../../helpers";
import { UploadMedia } from "../../../utils/mediaUpload";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import {
  useLazyGetTeamsDetailsByIdQuery,
  usePostAddTeamsMutation,
  usePutEditTeamCategoryMutation,
} from "../../../services/tournaments";
import { isString } from "../../../utils/validations";

const AddTeams = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { view } = useParams<string>();

  const [editTeamCategory, editTeamCategoryData] =
    usePutEditTeamCategoryMutation();
  const [image, setImage] = useState("");

  const [AddTeamsCategory, AddTeamsCategoryData] = usePostAddTeamsMutation();
  const [viewTeamCategory, viewTeamCategoryData] =
    useLazyGetTeamsDetailsByIdQuery();

  const [categoryName, setCategoryName] = useState("");

  const url = window.location.pathname;
  const path = url.includes("add");

  const viewTeamDetails = async () => {
    try {
      const res = await viewTeamCategory(view).unwrap();
      if (res?.statusCode === 200) {
        form.setFieldsValue({
          categoryName: res?.data?.name,
          image: res?.data?.logo,
        });
        setCategoryName(res?.data?.name);
        setImage(res?.data?.logo);
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  const handleImageUpload = async (file: RcFile) => {
    if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);

        if (res?.statusCode === 200) {
          setImage(res?.data);
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      errorToast("Only images are allowed");
    }

    return false;
  };

  const onFinish = async () => {
    let body = {
      logo: image,
      name: categoryName,
    };
    try {
      if (path) {
        const res = await AddTeamsCategory(body).unwrap();

        if (res?.statusCode === 200) {
          navigate("/favoriteteams", { replace: true });
          successToast("Team Added Successfully");
        }
      } else {
        {
          const res = await editTeamCategory({
            body,
            team_id: view,
          }).unwrap();

          if (res?.statusCode === 200) {
            navigate("/favoriteteams", { replace: true });
            successToast("Team Edited Successfully");
          }
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (view !== "add") {
      viewTeamDetails();
    }
  }, []);

  return (
    <div style={{ padding: 50 }}>
      <Card loading={viewTeamCategoryData.isLoading}>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2>{path ? "Add " : "Edit "}Team Categories</h2>
          <Button
            style={{
              background: "#ECAC35",
              width: 100,
              height: 40,
              color: "black",
            }}
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate("/favoriteteams", { replace: true });
            }}
          >
            Back
          </Button>
        </div>

        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            style={{
              maxWidth: "80%",
              marginTop: 10,
            }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Col xs={24} md={12}>
              <Form.Item
                name="image"
                rules={
                  [
                    // {
                    //   // required: true,
                    //   message: "Please upload image",
                    //   whitespace: true,
                    // },
                  ]
                }
              >
                <Upload
                  beforeUpload={handleImageUpload}
                  showUploadList={false}
                  className="upload_imgD"
                  accept="image/*"
                >
                  <div>
                    <h4>Upload Image</h4>
                    <Button icon={<UploadOutlined />}>Click to upload</Button>
                  </div>
                  {image ? (
                    <img
                      style={{
                        height: 110,
                        width: 110,
                        borderRadius: "50%",
                        marginTop: 20,
                      }}
                      src={image}
                      alt="profile"
                    />
                  ) : (
                    <></>
                  )}
                </Upload>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                name="categoryName"
                rules={[
                  {
                    required: true,
                    message: "Please input Team name",
                    whitespace: true,
                  },
                  {
                    min: 3,
                    message: "Name must be at least 5 characters",
                  },
                ]}
              >
                <div>
                  <h4>Add Team Name</h4>
                  <Input
                    value={categoryName}
                    placeholder="Team Name"
                    style={{ height: 50, width: "70%" }}
                    onChange={(e) => {
                      if (e.target.value === " " || e.target.value === ".") {
                      } else if (isString(e.target.value)) {
                        setCategoryName(e.target.value);
                      }
                    }}
                    maxLength={50}
                  />
                </div>
              </Form.Item>
            </Col>

            <Form.Item wrapperCol={{}}>
              <div style={{ marginTop: 10 }}>
                <Button
                  loading={
                    editTeamCategoryData.isLoading ||
                    AddTeamsCategoryData.isLoading
                  }
                  style={{
                    background: "#ECAC35",
                    width: 120,
                    height: 40,
                    margin: "auto",
                    color: "black",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  {path ? "Add" : "Edit"}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default AddTeams;
