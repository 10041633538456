import { Button, Card, Space, Checkbox } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import Table, { ColumnsType } from "antd/es/table";
import { useLazyGetLeagesByIdQuery } from "../../../services/tournaments";
import { useEffect, useState } from "react";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../hooks/store";

import {
  getMatchData,
  updateSelection,
} from "../../../reducers/matchDataSlice";
import { errorToast } from "../../../helpers";

const AddTournamentDetails = () => {
  const navigate = useNavigate();
  const [getLeagueById, getLeagueByIdData] = useLazyGetLeagesByIdQuery();
  const [leagueData, setLeagueData] = useState([]);
  const dispatch = useAppDispatch();

  const { id, view } = useParams();

  const matchData = useAppSelector(getMatchData);
  const [matchDetails, setMatchDetails] = useState<any[]>([]);

  interface DataType {
    fixture: any;
    teams: any;
    key: string;
    teamA: string;
    teamB: string;
    date: string;
    time: string;
  }

  // const checkBoxHandler = (item: any) => {
  //   const maxCheckedCount = 10;
  //   const fixtureId = item.fixture.id;
  //   const existingIndex = matchDetails.findIndex(
  //     (e) => e.fixture.id === fixtureId
  //   );

  //   if (existingIndex > -1) {
  //     const updatedLeagueData = matchDetails.filter(
  //       (data) => data.fixture.id !== fixtureId
  //     );
  //     setMatchDetails(updatedLeagueData);
  //   } else {
  //     if (matchDetails.length < maxCheckedCount) {
  //       const updatedLeagueData = [...matchDetails, item];
  //       setMatchDetails(updatedLeagueData);

  //       dispatch(
  //         updateSelection({
  //           details: {
  //             ...matchData,
  //             fixtures: updatedLeagueData,
  //           },
  //         })
  //       );
  //     }
  //   }
  // };

  const checkBoxHandler = (item: any) => {
    const maxCheckedCount = 10;
    const fixtureId = item.fixture.id;
    const existingIndex = matchDetails.findIndex(
      (e) => e.fixture.id === fixtureId
    );

    if (existingIndex > -1) {
      const updatedLeagueData = matchDetails.filter(
        (data) => data.fixture.id !== fixtureId
      );
      setMatchDetails(updatedLeagueData);

      dispatch(
        updateSelection({
          details: {
            ...matchData,
            fixtures: updatedLeagueData,
          },
        })
      );
    } else {
      if (matchDetails.length < maxCheckedCount) {
        const updatedLeagueData = [...matchDetails, item];
        setMatchDetails(updatedLeagueData);

        dispatch(
          updateSelection({
            details: {
              ...matchData,
              fixtures: updatedLeagueData,
            },
          })
        );
      }
    }
  };

  const navigateHandler = () => {
    if (!matchDetails?.length) {
      errorToast("You have to select atleast 1 match");
    } else if (view === "add") {
      navigate("/managetournamentscategories/add", {
        replace: true,
      });
    } else {
      navigate(`/managetournamentscategories/${view}`, {
        replace: true,
      });
    }
  };

  const backButton = () => {
    if (view === "add") {
      navigate("/managetournamentscategories/add", {
        replace: true,
      });
    } else {
      navigate(`/managetournamentscategories/${view}`, {
        replace: true,
      });
    }
  };

  // useEffect(() => {
  //   setMatchDetails(
  //     matchData?.fixtures?.length ? [...matchData?.fixtures] : []
  //   );
  // }, [matchData]);

  useEffect(() => {
    if (matchData?.fixtures) {
      setMatchDetails([...matchData.fixtures]);
    }
  }, [matchData]);

  const columns: ColumnsType<DataType> = [
    {
      title: "Team A",
      dataIndex: "teamA",
      key: "teamA",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{`${record?.teams?.home?.name || "N/A"}`}</span>
        </div>
      ),
    },
    {
      title: "Team B",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{`${record?.teams?.away?.name || "N/A"}`}</span>
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{`${
            moment(record?.fixture?.date).format("YYYY-MM-DD") || "N/A"
          }`}</span>
        </div>
      ),
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      align: "center",
      render: (_, record) => (
        <div>
          <span>{`${
            moment(record?.fixture?.date).format("HH:mm") || "N/A"
          }`}</span>
        </div>
      ),
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Checkbox
            checked={
              matchDetails.findIndex(
                (e) => e.fixture.id === record?.fixture?.id
              ) > -1
            }
            onClick={() => checkBoxHandler(record)}
          />
        </Space>
      ),
    },
  ];

  const getLeaguesForTournamentById = async () => {
    try {
      const res = await getLeagueById(id).unwrap();

      if (res?.statusCode === 200) {
        setLeagueData(res?.data);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLeaguesForTournamentById();
  }, []);
  return (
    <div style={{ padding: 40 }}>
      <Card loading={getLeagueByIdData?.isLoading}>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Button
            style={{
              background: "#ECAC35",
              width: 100,
              height: 40,
              color: "black",
              marginBottom: 20,
            }}
            type="primary"
            // htmlType="submit"
            onClick={() => backButton()}
          >
            Back
          </Button>
        </div>

        <div>
          <Table
            columns={columns}
            dataSource={leagueData}
            rowKey="_id"
            className="tableSelector"
            loading={getLeagueByIdData?.isLoading}
          />
        </div>
        <div style={{ paddingTop: 20 }}>
          <Button
            style={{
              background: "#ECAC35",
              width: 100,
              height: 40,
              color: "black",
            }}
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigateHandler();
            }}
          >
            Save
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default AddTournamentDetails;
