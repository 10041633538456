import { useEffect } from "react";
import "./App.css";

import RoutesNavigation from "./routes";
import { STORAGE_KEYS, getData } from "./helpers";
import { useNavigate } from "react-router-dom";
import LanguageContextProvider from "./context/LanguageContext";
import { log } from "console";

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = getData(STORAGE_KEYS.token);
    console.log(token, "token");

    if (window?.location?.pathname?.includes("resetpassword")) {
      navigate(window?.location?.pathname);
    } else if (token && window?.location?.pathname === "/") {
      window?.location?.replace("/dashboard");
    } else if (!token && window?.location?.pathname === "/") {
      navigate("/", { replace: true });
    } else if (token) {
      navigate(window?.location?.pathname);
    } else {
      navigate("/", { replace: true });
    }
  }, []);

  return (
    <LanguageContextProvider>
      <RoutesNavigation />
    </LanguageContextProvider>
  );
}

export default App;
