export const API_URL: string =
  process.env.REACT_APP_API_URL || "https://api.frediction.com/api/v1/admin/";
// export const API_URL: string =
//   process.env.REACT_APP_API_URL ||
//   "https://apistagingsportsprediction.appgrowthcompany.com/api/v1/admin/";

export const END_POINTS = {
  login: "login",
  forgetPassword: "forget/password",
  resetPassword: "reset/password",
  getProfile: "profile",
  mediaUpload: "upload",
  updateProfile: "profile",
  changePassword: "change/password",
  updateCms: "cms",
  getCms: "cms",
  addTokenCategory: "category",
  viewTokenCategory: "category",
  viewAllTokenCategory: "category",
  deleteToken: "category",
  editTokenCategory: "category",
  getUsers: "user",
  banners: "banner",
  faqs: "faq",
  subAdmin: "subAdmin",
  adminRole: "role",
  addNotification: "notification",
  getNotification: "notification",
  getLeague: "leagues",
  tournaments: "tournament",
  getLeagesById: "tournament/fixtures",
  getLeagueTournament: "tournament/leagues",
  getUserCsv: "export/user",
  getPredictionCsv: "export/predictions",
  getLeaguesData: "league/fixtures",
  dashboard: "dashboard",
  userPredictions: "user/prediction",
  userTournament: "user/tournaments",
  upcomingTournament: "upcoming",
  teams: "teams",
  reports: "reports",
  leaderBoard: "leaderboard",
  tokenList: "tokens",
  activeTournaments: "tournaments",
  receiverList: "receiver",
  settlement: "updateSettlement",
  userName: "user/usernames",
  userNamePredictions: "user/predictions",
  tournamentRanking: "tournament/rankings",
  tournamentPrediction: "tournament/predictions",
  allPrediction: "tournament/prediction",
  removeToken: "user/withdraw",
};
